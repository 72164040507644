import React from "react";
import loadable from "@loadable/component";

import PageLoader from "./components/loader/PageLoader";

export default [
  {
    path: "/",
    exact: true,
    component: loadable(() => import("./pages/Home"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/direct",
    exact: true,
    component: loadable(() => import("./pages/Live"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/emissions/:slug", "/emissions/:slug/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Program"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/emissions", "/emissions/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Videos"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: [
      "/emissions/:program/:slug",
      "/emissions/:program/:slug/:previewToken",
    ],
    exact: true,
    component: loadable(() => import("./pages/Video"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/articles/:slug", "/articles/:slug/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Category"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/articles", "/articles/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Stories"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/articles/:year/statique/:slug",
    exact: true,
    component: loadable(() => import("./pages/StoryStatic"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/articles/:year/:slug", "/articles/:year/:slug/:previewToken"],
    exact: true,
    component: loadable(() => import("./pages/Story"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/podcasts/:slug", "/podcasts/:slug/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Podcast"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/podcasts", "/podcasts/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Audios"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: [
      "/podcasts/:podcast/:slug",
      "/podcasts/:podcast/:slug/:previewToken",
    ],
    exact: true,
    component: loadable(() => import("./pages/Audio"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/dossiers", "/dossiers/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Topics"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/dossiers/:slug",
    exact: true,
    component: loadable(() => import("./pages/Topic"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: [
      "/auteurs/:slug/:tab(emissions|articles|podcasts)?",
      "/auteurs/:slug/:tab(emissions|articles|podcasts)/page/:page",
    ],
    exact: true,
    component: loadable(() => import("./pages/Author"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/tags/:slug", "/tags/:slug/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Tag"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/recherche/:query", "/recherche/:query/page/:page"],
    exact: true,
    component: loadable(() => import("./pages/Search"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/connexion",
    exact: true,
    component: loadable(() => import("./pages/Login"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/deconnexion",
    exact: true,
    component: loadable(() => import("./pages/Logout"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/mot-de-passe",
    exact: true,
    component: loadable(() => import("./pages/RequestPassword"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/mot-de-passe/:token",
    exact: true,
    component: loadable(() => import("./pages/ResetPassword"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/compte/abonnement",
    exact: true,
    component: loadable(() => import("./pages/Subscription"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/compte/methode-paiement",
    exact: true,
    component: loadable(() => import("./pages/PaymentMethod"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/compte",
    component: loadable(() => import("./pages/Account"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/soutien",
    exact: true,
    component: loadable(() => import("./pages/SupportLanding"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/soutien/:path(abonnement|socio|parts-sociales)",
    component: loadable(() => import("./pages/SupportPath"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: ["/newsletter", "/newsletters"],
    exact: true,
    component: loadable(() => import("./pages/Newsletters"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/annexes",
    component: loadable(() => import("./pages/Annexes"), {
      fallback: <PageLoader />,
    }),
  },
  {
    path: "/questions-recurrentes",
    exact: true,
    component: loadable(() => import("./pages/FAQ"), {
      fallback: <PageLoader />,
    }),
  },
  // {
  //   path: "/emplois",
  //   component: loadable(() => import("./pages/Jobs"), {
  //     fallback: <PageLoader />,
  //   }),
  // },
  // {
  //   path: "/ordre-du-jour",
  //   component: loadable(() => import("./pages/AssemblyAgenda"), {
  //     fallback: <PageLoader />,
  //   }),
  // },

  {
    path: "/campagne",
    component: loadable(() => import("./pages/Campaign"), {
      fallback: <PageLoader />,
    }),
  },
  {
    component: loadable(() => import("./components/NotFound"), {
      fallback: <PageLoader />,
    }),
  },
];
